import { initBookingTypesStage } from "./stages";

export const filterVenues = async (venues, venuesAttr) => {
    let filteredVenues;

    if ( venuesAttr === 'all' ) {
        // Create a new array which just contains the id and name for each venue
        filteredVenues = venues.map(venue => {
            return { id: venue._id, name: venue.title, city: venue.city }
        });
    } else {
        const venuesAtrrArray = venuesAttr.split(',');
        filteredVenues = [];

        // Create a new array which just contains the id and name for the specific booking type
        venues.forEach(venue => {
            if ( venuesAtrrArray.includes(venue._id) ) {
                const venueData = { id: venue._id, name: venue.title, city: venue.city };
    
                filteredVenues.push(venueData);
            }
        });
    }

    return filteredVenues;
}

export const enableVenueTriggers = (widget) => {
    const triggers = widget.querySelectorAll('.ig-dmn__venue-trigger');

    if ( triggers ) {
        triggers.forEach(trigger => {
            trigger.addEventListener('click', (event) =>{
                event.preventDefault();

                const venueId = trigger.getAttribute('data-venue-id');

                // Update the eidget venue-id attribute
                widget.setAttribute('data-venue-id', venueId);

                // Go to the booking types stage
                initBookingTypesStage(widget);
            });
        });
    }
}