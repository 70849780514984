import { apiPostRequest } from ".";
import { getBookingDetails, getBookingTypeDetails } from "../helpers/bookingData";

export const submitBooking = async (env, form) => {
    if ( env === 'test' ) {
        // Test env being used so don't actually post anything!
        return await postTestBooking();
    } else {
        // Post the booking details to DMN
        return await postBooking(form);
    }
};

export const postBooking = async (form) => {
    let formData = new FormData(form);
    formData.append('action', 'postBooking');

    const bookingResponse = apiPostRequest(formData);

    return bookingResponse;
};

export const postTestBooking = async () => {
    const bookingDetails = getBookingDetails();
    const bookingTypeDetails = getBookingTypeDetails(bookingDetails.type);

    const booking = {
        status: 'complete',
        type: {
            name: bookingTypeDetails.name
        },
        num_people: bookingDetails.num_people,
        date: bookingDetails.date,
    };

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(booking);
            reject('An error has occurred.')
        }, 400);
    });
};