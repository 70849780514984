import { apiPostRequest } from '.';
import { logDebugger } from '../helpers';
import { getBookingTypeDetails } from '../helpers/bookingData';

export const getVenueData = async () => {
    const formData = new FormData();
    formData.append('action', 'getVenueData');

    logDebugger('Getting venues from DMN - https://api.designmynight.com/v4/venues/?venue_group=VENUE_GROUP_ID');
    
    const responseJson = await apiPostRequest(formData);

    logDebugger('Venues:');
    logDebugger(responseJson);
    
    return responseJson;
};

export const getVenuePackages = async () => {
    const bookingTypeObj = getBookingTypeDetails();

    // Return any packages for the booking type selected
    return bookingTypeObj.packages ?? [];
};

export const getMarketingPreferences = async () => {
    const formData = new FormData();
    formData.append('action', 'getMarketingPreferences');

    const responseJson = await apiPostRequest(formData);
    
    return responseJson;
};