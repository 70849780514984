import { generateDurations, generateTimeArray, isBookingFormValid, scrollToWidgetTop, updateDurationDropdown, updateTimeDropdown } from ".";
import { submitBooking } from "../api/booking";
import { getBookingRules } from "../api/bookingRules";
import { getBookingTypeDetails, getDmnData } from "./bookingData";
import { getBookingTypeName } from "./bookingTypes";
import { showBookingCompleteModal } from "./modal";
import { getWidgetEnv, hideLoader, showLoader } from "./widget";

export const getEnquiryDetails = () => {
    return {
        type: getDmnData('bookingType'),
        venueId: getDmnData('venue')._id
    }
};

export const getEnquiryDetailsText = async () => {
    const bookingTypeDetails = getBookingTypeDetails();
    const bookingType = getBookingTypeName(bookingTypeDetails.name);
    
    const description = `You are making a ${bookingType} enquiry`;

    return description;
};

export const initEnquiryForm = (widget) => {
    const form = widget.querySelector('.ig-dmn__details-form');
    const env = getWidgetEnv(widget);
    
    form.addEventListener('submit', (event) => {
        event.preventDefault();
        
        // Show the loader
        showLoader(widget);

        const errorElem = form.querySelector('.ig-dmn-form-error');

        // Clear any active error messages
        errorElem.classList.add('hidden');

        const isValid = isBookingFormValid(form);

        if ( isValid ) {
            // Check if DOB is set to be required and update the hidden field if so
            if ( form.querySelector('.ig-dmn__form-dates') ) {
                const day = form.querySelector('.ig-dmn__form-dob-day').value;
                const month = form.querySelector('.ig-dmn__form-dob-month').value;
                const year = form.querySelector('.ig-dmn__form-dob-year').value;
                const dob = `${year}-${month}-${day}`;
                
                form.querySelector('.ig-dmn__form-dob').value = dob;
            }

            // Run the submitBooking function
            submitBooking(env, form).then(booking => {
                // Scroll to the top of the widget
                scrollToWidgetTop(widget);

                // Show the bookingComplete modal
                showBookingCompleteModal(widget, booking).then(() => {                
                    setTimeout(() => {
                        hideLoader(widget);
                    }, 400);
                });
            });
        } else {
            // Show the error message
            errorElem.classList.remove('hidden');

            // Hide the loader
            hideLoader(widget);
        }
    });
};

export const updateEnquiryFormFields = async (widget) => {
    const bookingTypeDetails = getBookingTypeDetails();
    const bookingRules = await getBookingRules(widget);
    const times = generateTimeArray(bookingRules.bookings_from, bookingRules.bookings_to, bookingTypeDetails.time_interval);
    const durations = generateDurations(bookingRules.min_duration, bookingRules.max_duration, bookingTypeDetails.duration_interval);

    // Update the enquiry time dropdown
    await updateTimeDropdown(widget, times);

    // Update the durations dropdown
    await updateDurationDropdown(widget, durations);
};