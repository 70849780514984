import { easepick } from '@easepick/bundle';
import { LockPlugin } from '@easepick/bundle';
import { showLoader, hideLoader } from '../helpers/widget';
import { initBookingValidationTriggers } from '../helpers/bookingForm';
import { formatDate } from '../helpers';
import { getBookingTypeClosedDays, getClosedDates, getOpenDates, getVenueClosedDays } from '../helpers/venue';
import { getBookingTypeDetails, getDmnData } from '../helpers/bookingData';
import { validateBooking } from "../api/bookingValidation";
import { updateEnquiryFormFields } from '../helpers/enquiry';

let picker;

export const initCalendar = (widget, stage = 'bookingDetails') => {
    if ( typeof(picker) !== 'undefined' ) {
        picker.destroy();
    }

    picker = new easepick.create(getPickerOptions(widget, stage));

    return picker;
};

const getPickerOptions = (widget, stage = 'bookingDetails') => {
    const venueClosedDates = getVenueClosedDays();
    const bookingTypeClosedDays = getBookingTypeClosedDays();
    const closedDays = [...new Set([...venueClosedDates, ...bookingTypeClosedDays])];    
    const closedDates = getClosedDates();
    const openDates = getOpenDates(closedDates, bookingTypeClosedDays);

    const bookingTypeDetails = getBookingTypeDetails();
    const advancedMaxDays = bookingTypeDetails.advance_bookings_max_days;
    let maxDate = false;

    if ( advancedMaxDays ) {
        let todayMax = new Date();
        maxDate = new Date(todayMax.setDate(todayMax.getDate() + advancedMaxDays));
    }

    return {
        element: widget.querySelector('.ig-dmn__form-datepicker'),
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
            'https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.0/dist/index.css'
        ],
        zIndex: 10,
        format: "DD MMMM YYYY",
        LockPlugin: {
            minDate: new Date(),
            filter(date, picked) {
                let disabledDates = [];

                // Add any days from the closedDays []
                if ( closedDays.length ) {
                    if ( closedDays.includes(date.getDay()) ) {
                        disabledDates.push(date.format('YYYY-MM-DD'));
                    }
                }
                
                // Add any specific dates from closedDates []
                if ( closedDates.length ) {
                    closedDates.forEach(closedDate => {
                        // Check that the date doesn't already exist in the disabledDates []
                        if ( !disabledDates.includes(closedDate) ) {
                            disabledDates.push(closedDate);
                        }
                    });
                }

                // Remove any dates that are set to be open in the override opening times
                disabledDates = disabledDates.filter(date => !openDates.includes(date));

                // Check if the max dates are set
                if ( maxDate ) {
                    if ( date > maxDate ) {
                        disabledDates.push(date.format('YYYY-MM-DD'));
                    }
                }

                // Disable all dates that are in the disabledDates []
                return disabledDates.includes(date.format('YYYY-MM-DD'));
            },
        },
        plugins: [
            LockPlugin
        ],
        setup(picker) {
            const venueId = getDmnData('venue')._id;
            
            picker.on('select', (e) => {
                const { date } = e.detail;
        
                showLoader(widget);
        
                // Update the booking forms hidden date field value
                const dateFormatted = formatDate(date);
                widget.querySelector('.ig-dmn__form-date').value = dateFormatted;
        
                if ( stage === 'bookingDetails' ) {
                    // Validate the booking
                    validateBooking(widget, 'calendar').then(response => {
                        // Init the booking validation triggers
                        initBookingValidationTriggers(widget);
    
                        setTimeout(() => {
                            hideLoader(widget);
                        }, 400);
                    });
                } else {
                    updateEnquiryFormFields(widget).then(() => {                        
                        setTimeout(() => {
                            hideLoader(widget);
                        }, 400);
                    });
                }
            });
        }
    };
}