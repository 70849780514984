import { showLoader, hideLoader } from "./widget";
import { showBookingForm, clearBookingForm, toggleFormDiscalimer } from './bookingForm';
import * as bookingData from './bookingData';
import { getOffers } from "../api/offers";
import { updateOffers } from "./offers";
import { resetNavButtons } from "./navigation";
import { toggleDepositSection } from "./deposit";
import { initBookingTypeFilterDropdown } from "../external/choices";
import { smoothScrollToElement } from ".";

export const filterBookingTypes = async (widget, bookingTypes, type) => {
    if ( type === 'Specific' ) {
        const bookingTypeId = widget.getAttribute('data-booking-type-id');
        const filteredTypesArray = [];
        let bookingTypeCat;

        // Create a new array which just contains the id and name for the specific booking type
        bookingTypes.forEach(bookingType => {
            if ( bookingType.id === bookingTypeId ) {
                filteredTypesArray.push({
                    id: bookingType.id,
                    name: bookingType.name,
                });

                bookingTypeCat = getCategoryFromName(bookingType.name);
            }
        });

        const filteredTypes = categorizeArray(filteredTypesArray, bookingTypeCat);

        return filteredTypes;
    } else {
        // Create a new array which just contains the id and name for each booking type
        const filteredTypesArray = bookingTypes.map(type => {
            return { id: type.id, name: type.name }
        });
    
        // Categorise the above array
        const filteredTypes = categorizeArray(filteredTypesArray, type);
        
        return filteredTypes;
    }
}

const getCategoryFromName = (fullName) => {
    // Split the name of the item using '_' as the separator
    const nameArr = fullName.split('_');

    return nameArr[0];
}

export const getBookingTypeName = (fullName) => {
    // Split the name of the item using '_' as the separator
    const [category, subcategory, name] = fullName.split('_');

    return name ? `${subcategory} ${name}` : subcategory;
}

const categorizeArray = (inputArray, type) => {
    // Initialize an empty object to store the categorized items
    const result = {};

    // Iterate through each item in the input array
    inputArray.forEach(item => {
        // Split the name of the item using '_' as the separator
        const [category, subcategory, name] = item.name.split('_');
        
        // Only carry on if the category matches the type given
        if ( category === type ) {            
            // Determine the category name based on whether a subcategory is present
            const categoryName = name ? subcategory : category;
    
            // If the category does not exist in the result object, create an empty array for it
            if (!result[categoryName]) {
                result[categoryName] = [];
            }
    
            // Create a new item with the desired structure
            const newItem = {
                id: item.id,
                name: name ? name : subcategory
            };
    
            // Add the new item to the corresponding category in the result object
            result[categoryName].push(newItem);
        }
    });

    // Return the final result object
    return result;
}

export const getBookingTypesHTML = async (bookingTypes, type) => {
    let formData = new FormData();
    formData.append('type', type);
    formData.append('booking_types', JSON.stringify(bookingTypes));
    formData.append('action', 'getBookingTypesHtml');

    const response = await fetch(igniteDmnJsVars.ajaxUrl, {
        method: "POST",
        body: formData
    });

    if (!response.ok) {
        throw new Error(`[${response.status}] ${response.statusText}`);
    }

    const bookingTypesHTML = await response.json();

    return bookingTypesHTML;
};

export const initBookingTypeFilters = (widget) => {
    const tabs = widget.querySelectorAll('.ig-dmn__booking-type-filter-tab');

    if ( tabs ) {
        tabs.forEach(tab => {
            tab.addEventListener('click', (event) => {
                event.preventDefault();

                showLoader(widget);

                const category = tab.getAttribute('data-booking-type-category');

                // Update the active tab
                updateActiveTab(widget, tab);

                // Filter the booking types based ont he category selected
                filterBookingTypesByCat(widget, category);

                setTimeout(() => {
                    hideLoader(widget);
                }, 400);
            });
        });
    }

    // Initialize the choices.js instance for the booking type filter dropdwon
    initBookingTypeFilterDropdown(widget);
}

const updateActiveTab = (widget, tab) => {
    const tabs = widget.querySelectorAll('.ig-dmn__booking-type-filter-tab');

    // Remove the active class from all tabs
    tabs.forEach(filterTab => {
        filterTab.classList.remove('active');
    });

    // Add the active class to the tab that was clicked
    tab.classList.add('active');
}

export const filterBookingTypesByCat = (widget, category) => {
    const bookingForm = widget.querySelector('.ig-dmn__booking-form');
    const bookingTypes = widget.querySelectorAll('.ig-dmn__booking-type-trigger');

    // Remove any active offers
    bookingData.updateActiveOffer(null);

    // Clear the booking form
    clearBookingForm(widget);
            
    // Hide the booking form
    bookingForm.classList.add('hidden');

    // Hide the offers section
    widget.querySelector('.ig-dmn__offers').classList.add('hidden');    

    // Reset the booking type data
    setBookingTypeData(widget).then(() => {  
        // Hide the offers section
        widget.querySelector('.ig-dmn__offers').classList.add('hidden');

        if ( bookingTypes ) {
            bookingTypes.forEach(type => {
                const bookingTypeCat = type.getAttribute('data-category');
    
                if ( bookingTypeCat === category ) {
                    // Show the bookingType
                    type.classList.remove('!hidden');
                } else {
                    // Hide the bookingType
                    type.classList.add('!hidden');
                }
            });

            // Clear the active class from any booking types
            bookingTypes.forEach(type => {
                // Remove the active class from all booking types
                type.classList.remove('active');

                // Revert the select text back to select for all booking types
                type.querySelector('.ig-dmn__booking-type-select').innerHTML = 'Select';
            });
        }
    });
}

export const enableBookingTypeTriggers = (widget) => {
    const triggers = widget.querySelectorAll('.ig-dmn__booking-type-trigger');

    if ( triggers ) {
        triggers.forEach(trigger => {
            trigger.addEventListener('click', (event) => {
                event.preventDefault();

                if ( 
                    !event.target.classList.contains('ig-dmn__booking-type-vtour-icon') && 
                    !event.target.classList.contains('ig-dmn__booking-type-vtour-icon-wrapper') ) {
                        // Only run if the virtual tour icon isn't clicked

                        const bookingTypeId = trigger.getAttribute('data-booking-type-id');
        
                        showLoader(widget);
        
                        // Set the booking type data
                        setBookingTypeData(widget, bookingTypeId).then(() => {
                            // Show/hide the form disclaimer
                            toggleFormDiscalimer(widget, bookingTypeId);

                            // Show the booking form and navigation
                            showBookingForm(widget);

                            // Scroll the widget down to the booking form
                            smoothScrollToElement(widget, '.ig-dmn__booking-form');
        
                            setTimeout(() => {
                                hideLoader(widget);
                            }, 400);
                        });
        
        
                        triggers.forEach(trig => {
                            // Remove the active class from all booking types
                            trig.classList.remove('active');
        
                            // Revert the select text back to select for all booking types
                            trig.querySelector('.ig-dmn__booking-type-select').innerHTML = 'Select';
                        });
                        
                        // Add the active class to the booking type selected
                        trigger.classList.add('active');
        
                        // Update the select text to say selected on the selected booking type
                        trigger.querySelector('.ig-dmn__booking-type-select').innerHTML = 'Selected';
                }
            });
        });
    }
};

const setBookingTypeData = async (widget, bookingTypeId = null) => {
    const venueId = bookingData.getDmnData('venue')._id;

    // Store the bookingType in localStorage
    bookingData.updateBookingType(bookingTypeId);

    // Update the form bookingType value
    widget.querySelector('.ig-dmn__form-dmn-type').value = bookingTypeId;

    // Get any offers that are available
    await getOffers(widget, venueId).then(offers => {
        updateOffers(widget, offers);
    });

    // Hide the deposit section
    toggleDepositSection(widget);

    // Reset the nav button visibility/state
    resetNavButtons(widget);
}

export const getTypeFromBookingTypeId = (bookingTypeId) => {
    const bookingType = bookingData.getBookingTypeDetails(bookingTypeId);
    const nameArr = bookingType.name.split('_');

    return nameArr[0];
};